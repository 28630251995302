// FONCTIONS JS COBRENROD
(function () {
    
    console.log('Cobrenord Scripts : Ready');

    var serializeForm = function (form) {
        var obj = {};
        var formData = new FormData(form);
        for (var key of formData.keys()) {
            obj[key] = formData.getAll(key);
        }
        return obj;
    };

    // CHECK if safari browser
    var isIos = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isIos) {
        document.body.classList.add('safari');
    }

    // AUTO SUBMIT FORM FILTER
    document.onchange = function(el){
        var formEl = el.srcElement.closest('form');
        if(formEl.classList.contains('auto-validate')) {
            formEl.submit();
        }
    };

    // SCROLL AUTO CALENDAR CURRENT MONTH
    var isCalendar = document.querySelectorAll('.page-template-calendar').length > 0;
    if(isCalendar) {
        const today = new Date();
        let month = today.getMonth() + 1;
        let monthCurrentPostEl = document.querySelectorAll('.list-month[id="month-' + month + '"]');
        let hash = window.location.hash.substring(1);
        if(monthCurrentPostEl.length > 0 && hash === "") {
            let monthCurrentEl = document.querySelector('.search_frise .month a[href="#month-' + month + '"]');
            setTimeout(() => {
                monthCurrentEl.click();
            }, 1000);
        }
    }

    // SELECT NUMBER FILTER ON CHANGE
    const filterCheckboxes = document.querySelectorAll('#filters-form input');
    if(filterCheckboxes.length > 0) {
        for (let i = 0; i < filterCheckboxes.length; i += 1) {
            filterCheckboxes[i].addEventListener('change', (e) => {
                const detail = e.target.closest('details');
                // Refresh number
                detail.querySelector('.number').innerHTML = detail.querySelectorAll('input:checked').length;
            });
        }
    }
    

    // ADD EVENT LISTENER TO
    // -> TOGGLE OTHER FILTER OPENED WHEN CLICKED IT
    // -> SELECT && DESELECT ALL FILTER WHEN OPEN
    // -> SELECT && DESELECT ALL NOTIFICATIONS SWITCHER (ea)
    window.addEventListener('load', () => {
        const filterForm = document.querySelector('#filters-form');
        if(filterForm !== null) {
            const subItems = filterForm.querySelectorAll('details');
            
            document.addEventListener('click', function toggleSubItem(e) {
                const el = e.target;
                const detail = el.closest('details');

                // SELECTALL && DESELECTALL (on filters menu list)
                if (el instanceof HTMLAnchorElement) {
                    const href = el.getAttribute('href');
                    if(href == "#selectall" || href == "#deselectall") {
                        const checkboxes = detail.querySelectorAll('input');
                        for(var i=0, n=checkboxes.length; i < n; i++) {
                            checkboxes[i].checked = href == "#selectall" ? true : false;
                        }

                        // Refresh number
                        detail.querySelector('.number').innerHTML = href == "#selectall" ? checkboxes.length : "0";
                    }
                }

                // TOGGLE OTHER FILTER OPENED
                subItems.forEach((item) => {
                    if((item !== detail) && item.open) {
                        item.open = false;
                    }
                });
            });
        }

        // SELECT && DESELECT ALL NOTIFICATIONS SWITCHER (ea)
        // -> Ajax on select / deselect notification in ajax.js
        const notificationsForm = document.querySelector('.table-preferences');
        if(notificationsForm !== null) {
            document.addEventListener('click', function toggleSwitchItem(e) {
                const el = e.target;
                const id = el.getAttribute('id');
                // only input (not catch labels)
                if(id !== null) {
                    if(id.includes('_notif') || id.includes('_email')) {
                        const name = el.getAttribute('name');
                        var checkboxes = null;
                        if(id == "all_cats_notif") {
                            checkboxes = notificationsForm.querySelectorAll('input[name*="notif"]');
                        } else if(id == "all_cats_email") {
                            checkboxes = notificationsForm.querySelectorAll('input[name*="email"]');
                        }

                        if(checkboxes !== null) {
                            for(var i=0, n=checkboxes.length; i < n; i++) {
                                checkboxes[i].checked = el.checked == true ? true : false;
                            }
                        }
                        
                        // input uniq
                        //if(name !== null) {
                            let formData = encodeURIComponent(JSON.stringify(serializeForm(notificationsForm)));

                            let request = new XMLHttpRequest();
                            request.open('GET', `${FunctionsAjax.ajaxurl}?action=ajax_notifications_push_user&data=${formData}&dataType=json&encode=true&security=${FunctionsAjax.security}`, true);
                            request.setRequestHeader("Content-Type", "application/json");

                            request.onload = function() {
                                if (200 === this.status) {
                                    let rep = JSON.parse(this.response);
                                    if(rep.type == "success") {
                                        // add message success
                                    }
                                } else {
                                    // add message error
                                    console.log('fail | status : ' + this.status + ' | response : ' + this.response);
                                }
                            };
                            request.onerror = function() {
                                console.log('connection error');
                            };
                            request.send();
                        //}
                    }
                }
            });
        }

        // WEBPUSHR CREATE USER && PROCESS SAVE ID IN USER ACF INPUT
        const toggleSubscription = document.querySelector('#webpushrSubscriptionToggleButton');
        const isInisialized = ((typeof webpushr !== 'undefined' && typeof webpushr === 'function') && toggleSubscription) ? true : false;

        // is connected && plugin active && is allowed notif
        if (document.body.classList.contains('logged-in') && isInisialized && toggleSubscription.checked) {
            setTimeout(function() {webpushrScriptReady()}, 1000);
        }

        if(toggleSubscription !== null) {
            toggleSubscription.addEventListener('click', () => {
                if(toggleSubscription.disabled) toggleSubscription.disabled = !toggleSubscription.disabled;
                if(toggleSubscription.checked) webpushrScriptReady()
            })
        }

    });

    // COUNT DIGIT ANIMATION
    const counters = document.querySelectorAll('.chiffre span');
    for(let n of counters) {
        const updateCount = () => {
            const target = + n.getAttribute('data-chiffre');
            const count = + n.innerText;
            const speed = 5000; // change animation speed here
            const inc = target / speed; 
            if(count < target) {
                n.innerText = Math.ceil(count + inc);
                setTimeout(updateCount, 10);
            } else {
                n.innerText = target;
            }
        }
        updateCount();
    }

    // CLASS SUBMITED ON FORM AFTER SUBMIT
    var forms = (window.collections) ? window.collections['forms'] : document.querySelectorAll('form');
    for(const form of forms) {
        form.addEventListener('submit', function (e) {
            form.classList.add('submited')
        });
    }
    

}) ();

function webpushrScriptReady(){
    webpushr('fetch_id',function (sid) { 
        let request = new XMLHttpRequest();
        request.open('GET', `${FunctionsAjax.ajaxurl}?action=ajax_notifications_push_id&data=${sid}&dataType=json&encode=true&security=${FunctionsAjax.security}`, true);
        request.setRequestHeader("Content-Type", "application/json");

        request.onload = function() {
            if (200 === this.status) {
                let rep = JSON.parse(this.response);
                if(rep.type == "success") {
                    // add message success
                }
            } else {
                // add message error
                console.log('fail | status : ' + this.status + ' | response : ' + this.response);
            }
        };
        request.onerror = function() {
            console.log('connection error');
        };
        request.send();
    });
}